<div class="reminders">
  <div class="reminders__header">
    <button mat-button class="reminders__add-reminder" color='primary' [disabled]='disableCreateNote'
      (click)="showForm()">
      <mat-icon>add_circle_outline</mat-icon>
      <span>New Reminder</span>
    </button>
  </div>

  <div class="reminders__body">

    <ng-container [ngSwitch]="reminderView">
      <ng-container *ngSwitchCase="'list'">
        <app-reminders-list [isLoading]="areRemindersLoading | async" [reminders]="existingReminders | async">
        </app-reminders-list>
      </ng-container>
      <ng-container *ngSwitchCase="'form'">
        <app-reminder-form [accountId]="accountId$ | async" [clientId]="clientId$ | async"
          (cancelEvent)="hideForm($event)">
        </app-reminder-form>
      </ng-container>
    </ng-container>
  </div>
</div>
