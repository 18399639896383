import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRemindersLoading, selectAll as selectAllReminders } from '../../state/reminders/reminders.selectors';
import { updateReminders } from '../../state/reminders/reminders.actions';
import { getSelectedDataSetId } from '../../state/data-set/data-set.selectors';
type ReminderView = 'list' | 'form';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  disableCreateNote = false;

  reminderView: ReminderView = 'list';

  constructor(private store: Store<any>) {}
  areRemindersLoading = this.store.select(selectRemindersLoading);
  existingReminders = this.store.select(selectAllReminders);

  clientId$ = this.store.select(getSelectedDataSetId);
  ngOnInit(): void {}
  showForm() {
    this.reminderView = 'form';
    this.disableCreateNote = true;
  }
  hideForm(event) {
    if (event === true) {
      this.store.dispatch(updateReminders());
    }

    this.reminderView = 'list';
    this.disableCreateNote = false;
  }
}
