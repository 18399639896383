import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldSelectOption, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { CodeWrapperService } from 'src/app/core/services/service-wrappers/code-wrapper.service';
import { RemarkCodesLookupService } from '../../../../core/services/lookup/remark-codes-lookup.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Console } from 'console';

@Component({
  selector: 'app-remark-codes-dialog',
  templateUrl: './remark-codes-dialog.component.html',
  styleUrls: ['./remark-codes-dialog.component.scss'],
  providers: [DatePipe]
})
export class RemarkCodesDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  sameCode = false;
  duplicateCode = false;
  CODE_TYPE = 'RemarkCode';
  remarkCodeId;
  //duplicateResponse = '';
  localResults;
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-4',
            validators: Validators.required,
            selectionChanged: (event) => {
              this.nameChange(event);
            }
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            initial: true,
            class: 'form-span-8',
            validators: Validators.required
          },
          {
            label: 'Effective From',
            name: 'effectiveFrom',
            type: 'date',
            class: 'form-span-6',
            validators: Validators.required,
            datepickerChanged: (event) => {
              this.validatorEffectiveToDate(event);
              this.sameNameTo(event);
            }
          },
          {
            label: 'Effective To',
            name: 'effectiveTo',
            type: 'date',
            class: 'form-span-6',
            customErrorMessage: 'Effective From date.',
            validators: Validators.required,
            datepickerChanged: (event) => {
              this.validatorEffectiveToDate(event);
              this.sameNameTo(event);
            }
          },
          {
            label: 'Description',
            name: 'name',
            type: 'text',
            class: 'form-span-12',
            validators: Validators.required
          },
      //  ]
      //},
      //{
      //  hideTitle: true,
      //  controls: [
          {
            label: 'Suppress Secondary Billing',
            name: 'suppressSecondary',
            type: 'checkbox',
            initial: false,
            class: 'form-span-12'
          },
          {
            label: 'Write Off Remainder Of Claim',
            name: 'writeoffRemainder',
            type: 'checkbox',
            initial: false,
            class: 'form-span-12'
          }
        ]
      }
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<RemarkCodesDialogComponent>,
    private service: CodeWrapperService,
    private dialogService: DialogService,
    public lookup: RemarkCodesLookupService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
    this.remarkCodeId = data?.remarkCodeId;
    const isEdit = this.remarkCodeId && this.remarkCodeId !== '';
    this.viewOnly = data?.viewOnly;
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }

  ngOnInit(): void {
    this.setupForm();

    if (this.remarkCodeId && this.remarkCodeId !== '') {
      this.service
        .apiV1CodeCodetypeIdGet(this.CODE_TYPE, this.remarkCodeId)
        .pipe(first())
        .subscribe((result) => {
          this.formGroup.patchValue(result);
          this.formGroup.get('active').patchValue(!result.inactive);
        });
    }
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  //throws error if name and date range values already exist
  nameChange(event) {
    if (event !== '') {
      let to = moment(new Date(this.datePipe.transform(this.formGroup.get('effectiveTo').value, 'MM/dd/yyyy')));
      //searches lookup service to query backend for current user name field input this is not exact match searhc so it brings back results that include the current user field input
      this.lookup.search(event).subscribe((result: any[]) => {
        this.localResults = result;

        //this result.find searches the results array from the lookup search to see if theres an exact match to the current user input
        let duplicate = result.find((o) => {
         return o.value?.code.toLowerCase() === event.toLowerCase()
        })
        if (duplicate !== undefined) {
          //sets same as code variable to true and then checks the date varibales with the sameNameTo
          this.sameCode = true
          this.sameNameTo(to, event)
        }
        else {
          this.sameCode = false
          this.duplicateCode = false
        }

      });
    }

  }

  //updates validity of name input if from date field fall within already existing range
  sameNameFrom(date) {
    if (this.formGroup.get('code').value !== null) {
      this.formGroup.get('effectiveTo').setValue(null);
    }
  }

  //updates validity of name input if To date field fall within already existing range
  sameNameTo(date, name: string = this.formGroup.get('code').value) {
    if (name > '') {
      this.duplicateCode = false;
      let dateFrom = this.datePipe.transform(this.formGroup.get('effectiveFrom').value, 'yyyy/MM/dd');
      let dateTo = this.datePipe.transform(this.formGroup.get('effectiveTo').value, 'yyyy/MM/dd');

      this.localResults.forEach((remarkCode) => {
        if (remarkCode.value.code.toLowerCase() === name.toLowerCase()) {
          let sameNameFromDate = this.datePipe.transform(remarkCode.value.effectiveFrom, 'yyyy/MM/dd');
          let sameNameToDate = this.datePipe.transform(remarkCode.value.effectiveTo, 'yyyy/MM/dd');
          if (this.remarkCodeId !== remarkCode.value.id) {  // don't test for the current Remark Code
            if (
              (dateFrom >= sameNameFromDate && dateFrom <= sameNameToDate) ||
              (dateTo >= sameNameFromDate && dateTo <= sameNameToDate) ||
              (dateFrom < sameNameFromDate && dateTo > sameNameToDate) ) {  // overlapping - superseding
              this.duplicateCode = true;
            }
          }
        }
      }); 
    }
  }

  //validates that effective To Date is Greater that effective From Date
  validatorEffectiveToDate(date) {
    const from = new Date(this.formGroup.get('effectiveFrom').value);
    const to = new Date(this.formGroup.get('effectiveTo').value);
    if (to < from && from !== null) {
      this.formGroup.get('effectiveTo').setErrors({ greaterDate: true });
      this.formGroup.get('effectiveFrom');
    } else {
      this.formGroup.get('effectiveTo').setErrors(null);
      this.formGroup.get('effectiveFrom').setErrors(null);
      this.formGroup.get('effectiveFrom').updateValueAndValidity();
    }
  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;

      const formData = {
        code: this.formGroup.get('code').value,
        name: this.formGroup.get('name').value,
        inactive: this.formGroup.get('active').value === true ? false : true,
        effectiveFrom: this.formGroup.get('effectiveFrom').value,
        effectiveTo: this.formGroup.get('effectiveTo').value,
        suppressSecondary: this.formGroup.get('suppressSecondary').value,
        writeoffRemainder: this.formGroup.get('writeoffRemainder').value
      };

      let saveObservable: Observable<any>;
      let notification;
      if (this.remarkCodeId) {
        notification = 'Remark Code Updated';
        saveObservable = this.service
          .apiV1CodeCodetypePatchIdPatch(this.CODE_TYPE, this.remarkCodeId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'Remark Code Added';
        saveObservable = this.service.apiV1CodeCodetypeAddPost(this.CODE_TYPE, formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Saving Remark Code')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }
}
