import { Injector, LOCALE_ID } from '@angular/core';
import { ColumnDefinition, ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { Report } from '../../models/report';
import { ARAgedTrialBalanceService } from './a-r-aged-trial-balance.service';
import { Validators } from '@angular/forms';
import { AttendingPhysiciansLookupService, FinancialClassesLookupService, InsuranceCarriersLookupService, LocationsLookupService } from '@core/services/lookup';

const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const ARAgedTrialBalance: Report = {
  id: 'aging-trial-balance',
  label: 'A/R Aged Trial Balance',
  subText: 'If no filters are selected, then all data is included.',
  searchFormClass: 'report-form--hide-border',
  textCriteria: { 'Include 0-30': false, 'Include 31-60': false, 'Include 61-90': false, 'Include 91-120': false, 'Include 121-150': false, 'Include 151-': false },
  cleanFilterParams: {
    'locationIds': '',
    'financialClassIds': '',
    'attendingProviderIds': '',
    'primaryInsuranceCarrierIds': '',
    'responsibleInsuranceCarrierIds': ''
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Report Format',
          name: 'reportFormat',
          class: 'form-span-6',
          type: 'select',
          options: [
            {
              label: 'By Financial Class',
              value: 'finClass'
            },
            {
              label: 'By Financial Class within Location',
              value: 'classLoc'
            },
            {
              label: 'By Location within Financial Class',
              value: 'locClass'
            },
            {
              label: 'By Primary Carrier',
              value: 'primCarrier'
            },
            {
              label: 'By Provider',
              value: 'provider'
            },
            {
              label: 'By Provider within Location',
              value: 'providerLoc'
            },
            {
              label: 'By Responsibility',
              value: 'resp'
            },
            {
              label: 'By Responsibility within Financial Class',
              value: 'respClass'
            },
            {
              label: 'By Responsible Carrier',
              value: 'respCarrier'
            },
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Report Format'] = event.source.triggerValue;
            var selection = event.source.value;
            if (selection === 'resp' || selection === 'respClass' || selection === 'respCarrier') {
              ARAgedTrialBalance.formGroup.controls["asOfDate"].patchValue('');
              ARAgedTrialBalance.formGroup.controls["asOfDate"].disable();
            }
            else {
              ARAgedTrialBalance.formGroup.controls["asOfDate"].enable();
            }
            if (selection === 'classLoc' || selection === 'locClass' || selection === 'respClass' || selection === 'providerLoc') {
              selectedColumnDefinitions = ARAgedTrialBalance.gridColumnDefinitions[1];
            }
            else {
              selectedColumnDefinitions = ARAgedTrialBalance.gridColumnDefinitions[0];
            }
          }
        },
        {
          label: 'As-Of Date',
          name: 'asOfDate',
          class: 'form-span-6',
          type: 'date',
          datepickerChanged: (event) => {
            var selectedDate = event?.value?.toLocaleDateString() ?? '';
            ARAgedTrialBalance.textCriteria['As-Of Date'] = selectedDate;
            var today = new Date().toLocaleDateString();
            if (selectedDate !== '' && selectedDate !== today) {
              ARAgedTrialBalance.formGroup.controls["responsibleInsuranceCarrierIds"].patchValue('');
              ARAgedTrialBalance.formGroup.controls["responsibleInsuranceCarrierIds"].disable();
              ARAgedTrialBalance.formGroup.controls["responsibilityTypes"].patchValue('');
              ARAgedTrialBalance.formGroup.controls["responsibilityTypes"].disable();
            } else {
              ARAgedTrialBalance.formGroup.controls["responsibleInsuranceCarrierIds"].enable();
              ARAgedTrialBalance.formGroup.controls["responsibilityTypes"].enable();
            }
          }
        },
        {
          label: 'Responsibility Types',
          name: 'responsibilityTypes',
          options: ['Primary', 'Secondary', 'Tertiary', 'Guarantor'],
          type: 'select',
          multiple: true,
          class: 'form-span-6',
          selectionChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Responsibility Types'] = event.source.triggerValue;
          }
        },
        {
          matLabel: 'Attending Provider',
          name: 'attendingProviderIds',

          apiService: injector.get(AttendingPhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Attending Provider',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            ARAgedTrialBalance.textCriteria['Attending Providers'] = listText.join(',');
            ARAgedTrialBalance.cleanFilterParams['attendingProviderIds'] = listValue

          }
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            ARAgedTrialBalance.textCriteria['Financial Classes'] = listText.join(',');
            ARAgedTrialBalance.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            ARAgedTrialBalance.textCriteria['Locations'] = listText.join(',');
            ARAgedTrialBalance.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          matLabel: 'Primary Insurance Carrier',
          name: 'primaryInsuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Primary Insurance Carrier',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ARAgedTrialBalance.textCriteria['Primary Insurance Carrier'] = listText.join(',');
            ARAgedTrialBalance.cleanFilterParams['primaryInsuranceCarrierIds'] = listValue
          },
        },
        {
          matLabel: 'Responsible Insurance Carrier',
          name: 'responsibleInsuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Responsible Insurance Carrier',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ARAgedTrialBalance.textCriteria['Responsible Insurance Carrier'] = listText.join(',');
            ARAgedTrialBalance.cleanFilterParams['responsibleInsuranceCarrierIds'] = listValue
          },
        },
        {
          label: 'Include Patient Detail',
          name: 'showDetails',
          class: 'form-span-6',
          type: 'toggle',
          initial: true,
          toggleChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Include Patient Detail'] = event.checked === true ? 'Yes' : 'No'
          }
        },
        {
          label: 'Include Accounts with a Balance Aged:',
          class: 'form-span-12',
          type: 'label',
        },
        {
          label: '0 - 30 Days',
          name: 'include_0',
          type: 'checkbox',
          class: 'form-span-4',
          checkedChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Include 0-30'] = event.checked;
          }
        },
        {
          label: '31 - 60 Days',
          name: 'include_31',
          type: 'checkbox',
          class: 'form-span-4',
          checkedChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Include 31-60'] = event.checked;
          }
        },
        {
          label: '61 - 90 Days',
          name: 'include_61',
          type: 'checkbox',
          class: 'form-span-4',
          checkedChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Include 61-90'] = event.checked;
          }
        },
        {
          label: '91 - 120 Days',
          name: 'include_91',
          type: 'checkbox',
          class: 'form-span-4',
          checkedChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Include 91-120'] = event.checked;
          }
        },
        {
          label: '121 - 150 Days',
          name: 'include_121',
          type: 'checkbox',
          class: 'form-span-4',
          checkedChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Include 121-150'] = event.checked;
          }
        },
        {
          label: 'Over 150 Days',
          name: 'include_150_over',
          type: 'checkbox',
          class: 'form-span-4',
          checkedChanged: (event) => {
            ARAgedTrialBalance.textCriteria['Include 151-'] = event.checked;
          }
        }
      ]
    }
  ],
  gridColumnDefinitions: [
    [
      {
        id: 'Dynamic Column 1',
        displayKey: 'Dynamic Column 1',
        headerText: 'Dynamic Column 1',
        dynamic: true,
        displayOrder: 0,
        columnSize: ColumnSizes.normal
      },
      {
        id: 'Account Number',
        displayKey: 'Account Number',
        headerText: 'Account Number',
        displayOrder: 2,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Patient',
        displayKey: 'Patient',
        headerText: 'Patient Name',
        displayOrder: 3,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days0to30',
        displayKey: 'Days0to30',
        headerText: 'Current',
        displayOrder: 4,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent0to30',
        displayKey: 'Percent0to30',
        headerText: 'Current %',
        displayOrder: 5,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days31to60',
        displayKey: 'Days31to60',
        headerText: 'Aged 31 - 60 Days',
        displayOrder: 6,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent31to60',
        displayKey: 'Percent31to60',
        headerText: '31 - 60 %',
        displayOrder: 7,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days61to90',
        displayKey: 'Days61to90',
        headerText: 'Aged 61 - 90 Days',
        displayOrder: 8,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent61to90',
        displayKey: 'Percent61to90',
        headerText: '61 - 90 %',
        displayOrder: 9,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days91to120',
        displayKey: 'Days91to120',
        headerText: 'Aged 91 - 120 Days',
        displayOrder: 10,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent91to120',
        displayKey: 'Percent91to120',
        headerText: '91 - 120 %',
        displayOrder: 11,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days121to150',
        displayKey: 'Days121to150',
        headerText: 'Aged 121 - 150 Days',
        displayOrder: 12,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent121to150',
        displayKey: 'Percent121to150',
        headerText: '121 - 150 %',
        displayOrder: 13,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'DaysOver150',
        displayKey: 'DaysOver150',
        headerText: 'Over 150 Days',
        displayOrder: 14,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'PercentOver150',
        displayKey: 'PercentOver150',
        headerText: 'Over 150 %',
        displayOrder: 15,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Balance',
        displayKey: 'Balance',
        headerText: 'Balance',
        displayOrder: 16,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Percent of Total',
        displayKey: 'Percent of Total',
        headerText: 'Percent of Total',
        displayOrder: 17,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'AccountCount',
        displayKey: 'AccountCount',
        headerText: '# of Accounts',
        displayOrder: 18,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
    ],
    [
      {
        id: 'Dynamic Column 1',
        displayKey: 'Dynamic Column 1',
        headerText: 'Dynamic Column 1',
        dynamic: true,
        displayOrder: 0,
        columnSize: ColumnSizes.normal
      },
      {
        id: 'Dynamic Column 2',
        displayKey: 'Dynamic Column 2',
        headerText: 'Dynamic Column 2',
        dynamic: true,
        displayOrder: 1,
        columnSize: ColumnSizes.normal
      },
      {
        id: 'Account Number',
        displayKey: 'Account Number',
        headerText: 'Account Number',
        displayOrder: 2,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Patient',
        displayKey: 'Patient',
        headerText: 'Patient Name',
        displayOrder: 3,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days0to30',
        displayKey: 'Days0to30',
        headerText: 'Current',
        displayOrder: 4,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent0to30',
        displayKey: 'Percent0to30',
        headerText: 'Current %',
        displayOrder: 5,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days31to60',
        displayKey: 'Days31to60',
        headerText: 'Aged 31 - 60 Days',
        displayOrder: 6,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent31to60',
        displayKey: 'Percent31to60',
        headerText: '31 - 60 %',
        displayOrder: 7,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days61to90',
        displayKey: 'Days61to90',
        headerText: 'Aged 61 - 90 Days',
        displayOrder: 8,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent61to90',
        displayKey: 'Percent61to90',
        headerText: '61 - 90 %',
        displayOrder: 9,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days91to120',
        displayKey: 'Days91to120',
        headerText: 'Aged 91 - 120 Days',
        displayOrder: 10,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent91to120',
        displayKey: 'Percent91to120',
        headerText: '91 - 120 %',
        displayOrder: 11,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Days121to150',
        displayKey: 'Days121to150',
        headerText: 'Aged 121 - 150 Days',
        displayOrder: 12,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'Percent121to150',
        displayKey: 'Percent121to150',
        headerText: '121 - 150 %',
        displayOrder: 13,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'DaysOver150',
        displayKey: 'DaysOver150',
        headerText: 'Over 150 Days',
        displayOrder: 14,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true,
        formatter: moneyFormat.format
      },
      {
        id: 'PercentOver150',
        displayKey: 'PercentOver150',
        headerText: 'Over 150 %',
        displayOrder: 15,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Balance',
        displayKey: 'Balance',
        headerText: 'Balance',
        displayOrder: 16,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'Percent of Total',
        displayKey: 'Percent of Total',
        headerText: 'Percent of Total',
        displayOrder: 17,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      {
        id: 'AccountCount',
        displayKey: 'AccountCount',
        headerText: '# of Accounts',
        displayOrder: 18,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      }
    ]
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    return {
      service: injector.get<any>(ARAgedTrialBalanceService),
      columnDefinitions: selectedColumnDefinitions
    };
  }
};
let selectedColumnDefinitions: ColumnDefinition[] = ARAgedTrialBalance.gridColumnDefinitions[0];


