import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { Report } from '../../models/report';
import { LocationsLookupService, AttendingPhysiciansLookupService } from '../../../../core/services/lookup';
import { ColumnSizes, FieldSelectOption, ColumnDefinition } from 'components';
import { ReportingTypeAnalysisReportService } from './reporting-type-analysis-report.service';
import { TypeOfServiceCodesLookupService } from '@core/services/lookup/type-of-service-codes-lookup.service';
import { ReportTypesLookupService } from '@core/services/lookup/report-types-lookup.service';

const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const ReportingTypeAnalysisReport: Report = {
    id: 'reporting-type-analysis-report',
    label: 'Reporting Type Analysis Report',
    textCriteria: { 'Date Range Type': '', 'Start Date': '', 'End Date': '', 'Locations': '', 'Attending Providers': '', 'Reporting Types': '', 'Report Format': ''},
    cleanFilterParams: {
        'locationIds': '', 'attendingProviderIds': '', 'reportingTypes': ''
    },
    getReportTitle: () => {
        return ReportingTypeAnalysisReport.label + ' ' + ReportingTypeAnalysisReport.textCriteria['Start Date'] + ' - ' + ReportingTypeAnalysisReport.textCriteria['End Date']
          + ' (' + ReportingTypeAnalysisReport.textCriteria['Date Range Type'] + ')';
    },
    getSearchCriteria: (injector: Injector) => [
        {
          title: '',
          controls: [
            {
              label: 'Range Type',
              name: 'dateRangeType',
              type: 'select',
              class: 'form-span-6 ',
              options: [
                {
                  label: 'Service Date',
                  value: 'S'
                },
                {
                  label: 'Posting Date',
                  value: 'P'
                }
              ],
              validators: Validators.required,
              selectionChanged: (event) => {
                ReportingTypeAnalysisReport.textCriteria['Date Range Type'] = event.source.triggerValue;
              }
            },
            {
              label: 'Date Range',
              name: 'dateRange',
              type: 'dateRange',
              class: 'form-span-6',
              daterangeStartChanged: (event) => {
                ReportingTypeAnalysisReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
              },
              daterangeEndChanged: (event) => {
                ReportingTypeAnalysisReport.textCriteria['End Date'] = event.value.toLocaleDateString();
              }
            },
            {
              label: '',
              name: 'dateRangeStart',
              type: 'noInput',
              class: 'display-none',
              validators: Validators.required
            },
            {
              label: '',
              name: 'dateRangeEnd',
              type: 'noInput',
              class: 'display-none',
              validators: Validators.required
            },
            {
              matLabel: 'Location',
              name: 'locationIds',
              apiService: injector.get(LocationsLookupService),
              type: 'multiSelect',
              class: 'form-span-12 multi-input',
              placeholder: 'Location',
              selectOptions: (event) => {
                let listValue = []
                let listText = []
                event.forEach((x: FieldSelectOption) => {
    
                  listValue.push(x.value)
                  listText.push(x.label)
                })
                ReportingTypeAnalysisReport.textCriteria['Locations'] = listText.join(',');
                ReportingTypeAnalysisReport.cleanFilterParams['locationIds'] = listValue
              }
            },
            {
              matLabel: 'Attending Provider',
              name: 'attendingProviderIds',
              apiService: injector.get(AttendingPhysiciansLookupService),
              type: 'multiSelect',
              class: 'form-span-12 multi-input',
              placeholder: 'Attending Provider',
              selectOptions: (event) => {
                let listValue = []
                let listText = []
                event.forEach((x: FieldSelectOption) => {
                  listValue.push(x.value)
                  listText.push(x.label)
                })
                ReportingTypeAnalysisReport.textCriteria['Attending Providers'] = listText.join(',');
                ReportingTypeAnalysisReport.cleanFilterParams['attendingProviderIds'] = listValue
    
              }
            },
            {
                matLabel: 'Reporting Type',
                name: 'reportingTypes',
                apiService: injector.get(ReportTypesLookupService),
                type: 'multiSelect',
                class: 'form-span-12 multi-input',
                placeholder: 'Reporting Type',
                selectOptions: (event) => {
                  let listValue = []
                  let listText = []
                  event.forEach((x: FieldSelectOption) => {
                    listValue.push(x.value)
                    listText.push(x.label)
                  })
                  ReportingTypeAnalysisReport.textCriteria['Reporting Types'] = listText.join(',');
                  ReportingTypeAnalysisReport.cleanFilterParams['reportingTypes'] = listValue
      
                }
              },
            {
              label: 'Report Format',
              name: 'reportFormat',
              class: 'form-span-12',
              type: 'select',
              options: [
                {
                  label: 'by attending/reporting type within location',
                  value: 'P'
                },
                {
                  label: 'by reporting type within location',
                  value: 'L'
                }
              ],
              validators: Validators.required,
              selectionChanged: (event) => {
                ReportingTypeAnalysisReport.textCriteria['Report Format'] = event.source.triggerValue;
                if (event.source.value === 'P') selectedColumnDefinitions = ReportingTypeAnalysisReport.gridColumnDefinitions[0];
                else selectedColumnDefinitions = ReportingTypeAnalysisReport.gridColumnDefinitions[1];
              }
            }
          ]
        }
    ],
    gridColumnDefinitions: [
        [
            {
              id: 'Location',
              displayKey: 'LocationName',
              headerText: 'Location',
              displayOrder: 0,
              columnSize: ColumnSizes.normal,
              sortable: true,
              filterable: true
            },
            {
              id: 'Attending Provider',
              displayKey: 'AttendingProviderName',
              headerText: 'Attending Provider',
              displayOrder: 1,
              columnSize: ColumnSizes.normal,
              sortable: true,
              filterable: true
            },
            {
              id: 'Reporting Type',
              displayKey: 'ReportingType',
              headerText: 'Reporting Type',
              displayOrder: 2,
              columnSize: ColumnSizes.extraWide,
              sortable: true,
              filterable: true
            },
            {
              id: 'Quantity',
              displayKey: 'Quantity',
              headerText: 'Quantity',
              displayOrder: 3,
              columnSize: ColumnSizes.narrow,
              sortable: true,
              filterable: true
            },
            {
              id: 'Amount',
              displayKey: 'ProcedureAmount',
              headerText: 'Amount',
              displayOrder: 4,
              columnSize: ColumnSizes.normal,
              formatter: moneyFormat.format,
              sortable: true,
              filterable: true
            },
            {
              id: 'YTD Quantity',
              displayKey: 'YtdQuantity',
              headerText: 'YTD Quantity',
              displayOrder: 5,
              columnSize: ColumnSizes.narrow,
              sortable: true,
              filterable: true
            },
            {
              id: 'YTD Amount',
              displayKey: 'YtdProcedureAmount',
              headerText: 'YTD Amount',
              displayOrder: 7,
              columnSize: ColumnSizes.normal,
              formatter: moneyFormat.format,
              sortable: true,
              filterable: true
            }
        ],
        [
            {
              id: 'Location',
              displayKey: 'LocationName',
              headerText: 'Location',
              displayOrder: 0,
              columnSize: ColumnSizes.normal,
              sortable: true,
              filterable: true
            },
            {
              id: 'Reporting Type',
              displayKey: 'ReportingType',
              headerText: 'Reporting Type',
              displayOrder: 1,
              columnSize: ColumnSizes.extraWide,
              sortable: true,
              filterable: true
            },
            {
              id: 'Quantity',
              displayKey: 'Quantity',
              headerText: 'Quantity',
              displayOrder: 2,
              columnSize: ColumnSizes.narrow,
              sortable: true,
              filterable: true
            },
            {
              id: 'Amount',
              displayKey: 'ProcedureAmount',
              headerText: 'Amount',
              displayOrder: 3,
              columnSize: ColumnSizes.normal,
              formatter: moneyFormat.format,
              sortable: true,
              filterable: true
            },
            {
                id: 'Payment Amount',
                displayKey: 'PaymentAmount',
                headerText: 'Payment Amount',
                displayOrder: 4,
                columnSize: ColumnSizes.normal,
                formatter: moneyFormat.format,
                sortable: true,
                filterable: true
            }
          ]
    ],
    getGridConfiguration: (injector: Injector) => {
        const locale = injector.get(LOCALE_ID);
        return {
          service: injector.get<any>(ReportingTypeAnalysisReportService),
          columnDefinitions: selectedColumnDefinitions
        };
      }
}
let selectedColumnDefinitions: ColumnDefinition[] = ReportingTypeAnalysisReport.gridColumnDefinitions[0];

