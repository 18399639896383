import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';
import { ProcedureWrapperService } from '../service-wrappers/procedure-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureCodesLookupService extends ApiAutoCompleteService {
  constructor(private service: ProcedureWrapperService) {
    super();
    this.dynamicSearch = true;
  }

  existingValues: string []= [];

  procedureCodesEmpty$ = this.service
    .apiV1ProcedureAllPost({ generalSearch: '', pageSize: 9999 })
    // .apiV1CodeCodetypePost('ProcedureCode', {
    //   generalSearch: ''
    // })
    .pipe(shareReplay(1));

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.code} - ${result.description}`,
      pendingCommit: result.pendingCommit,
      inactive: result.inactive,
      shortLabel:result.code
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.procedureCodesEmpty$.pipe(map((results) => results.data.map(this.mapRow).filter(prc => (!prc.inactive || this.existingValues.includes(prc.value)))));
    }

    return this.service
      .apiV1ProcedureAllPost({
        generalSearch: value,
        pageSize: 100
      })
      .pipe(map((results) => results.data.map(this.mapRow).filter(prc => (!prc.inactive || this.existingValues.includes(prc.value)))));
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1ProcedureIdGet(id).pipe(map(this.mapRow));
  }
}
