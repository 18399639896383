import { Injectable } from '@angular/core';
import {
  ProcedureService,
  ProcedureDetailsViewModel,
  ProcedureDetailsViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureWrapperService extends BaseWrapperService {
  constructor(private service: ProcedureService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1ProcedureAllPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<ProcedureDetailsViewModelPagedModelResponse> {
    return this.service.apiV1ProcedureAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1ProcedureIdGet(id: string): Observable<ProcedureDetailsViewModel> {
    return this.service.apiV1ProcedureIdGet(id, this.getDataSetId());
  }

  apiV1ProcedureInsuranceProcedurePost(requestBody): Observable<ProcedureDetailsViewModel> {
    return this.service.apiV1ProcedureInsuranceProcedurePost(this.getDataSetId(), requestBody);
  }

  apiV1ProcedureAddPost(requestBody): Observable<ProcedureDetailsViewModel> {
    return this.service.apiV1ProcedureAddPost(this.getDataSetId(), requestBody);
  }

  apiV1ProcedurePatchIdPatch(id: string, operation): Observable<ProcedureDetailsViewModel> {
    return this.service.apiV1ProcedurePatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1ProcedureIdDelete(id: string): Observable<ProcedureDetailsViewModel> {
    return this.service.apiV1ProcedureIdDelete(id, this.getDataSetId());
  }
}
