/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ClaimStatusViewModel {
  visitId?: string;
  patientId?: string;
  accountNumber?: number;
  visit?: number;
  serviceDate?: string;
  insuranceCarrierNumber?: string | null;
  insuranceCarrierName?: string | null;
  fullName?: string | null;
  locationName?: string | null;
  receivedDate?: string;
  categoryCode?: string | null;
  statusCode?: string | null;
  description?: string | null;
  claimStatusId?: string;
  balance?: number;
}
