import { Injectable } from '@angular/core';
import {
  FinancialClassService,
  FinancialClassViewModel,
  FinancialClassViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialClassWrapperService extends BaseWrapperService {
  constructor(private service: FinancialClassService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1FinancialClassAllPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<FinancialClassViewModelPagedModelResponse> {
    return this.service.apiV1FinancialClassAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1FinancialClassIdGet(id: string): Observable<FinancialClassViewModel> {
    return this.service.apiV1FinancialClassIdGet(id, this.getDataSetId());
  }

  apiV1FinancialClassAddPost(requestBody): Observable<FinancialClassViewModel> {
    return this.service.apiV1FinancialClassAddPost(this.getDataSetId(), requestBody);
  }

  apiV1FinancialClassPatchIdPatch(id: string, operation): Observable<FinancialClassViewModel> {
    return this.service.apiV1FinancialClassPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1FinancialClassIdDelete(id: string): Observable<FinancialClassViewModel> {
    return this.service.apiV1FinancialClassIdDelete(id, this.getDataSetId());
  }
}
