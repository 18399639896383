/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface VisitsPendingEligibilityViewModel {
  id?: string;
  accountNumber?: number;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  serviceDate?: string;
  insuranceId?: string;
  insuranceCarrierName?: string | null;
  balance?: number;
  requestDate?: string;
}
