import { Component, Input, OnInit } from '@angular/core';

// TODO: On Push
@Component({
  selector: 'lib-sub-page-layout',
  templateUrl: './sub-page-layout.component.html',
  styleUrls: ['./sub-page-layout.component.scss']
})
export class SubPageLayoutComponent implements OnInit {
  @Input() useSideBar = true;
  @Input() useNav = true;
  @Input() sideNavWidth = 'normal';
  constructor() {}

  ngOnInit(): void {}

  setSideNavWidth() {
    if (this.sideNavWidth == 'wide') return 'wide'; 
    return '';
  }
}
