import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldDefinition, FormIssue } from '../models';

@Component({
  selector: 'lib-form-group',
  template: `
    <fieldset [disabled]="disabled" [class]="elementClass" *ngIf="!hide">
      <h2 class="lib-form-group__title">{{ title }}</h2>
      <div class="lib-form-group__fields-container">
        <ng-content *ngIf="!formGroupDefinition" class="lib-form-group__fields"></ng-content>
        <ng-container *ngIf="formGroupDefinition">
          <lib-form-field
            *ngFor="let definition of formGroupDefinition; trackBy: trackByIndex"
            [parent]="formGroup"
            [class]="definition.class"
            [type]="definition.type"
            [name]="definition.name"
            [label]="definition.label"
            [customSelectedDisplay]="definition.customSelectedDisplay"
            [focusId]="definition.focusId"
            [hint]="definition.hint"
            [errors]="definition.errors"
            [options]="definition.options"
            [emptyOption]="definition.emptyOption"
            [clearButton]="definition.clearButton"
            [placeholder]="definition.placeholder"
            [checkedText]="definition.checkedText"
            [uncheckedText]="definition.uncheckedText"
            [multiple]="definition.multiple"
            [isReadOnly]="definition.isReadOnly"
            [excludeSelectAllOption]="definition.excludeSelectAllOption"
            [includeSystemOption]="definition.includeSystemOption"
            [min]="definition.min"
            [max]="definition.max"
            [matLabel]="definition.matLabel"
            [apiService]="definition.apiService"
            [fieldIssues]="fieldIssues(definition.name)"
            [initialMulti]="definition.initialMulti" 
            (selectOptions)="definition.selectOptions ? definition?.selectOptions($event) : void()"
            (selectionChanged)="definition.selectionChanged ? definition?.selectionChanged($event) : void()"
            (datepickerChanged)="definition.datepickerChanged ? definition?.datepickerChanged($event) : void()"
            (checkedChanged)="definition.checkedChanged ? definition?.checkedChanged($event) : void()"
            (toggleChanged)="definition.toggleChanged ? definition?.toggleChanged($event) : void()"
            (daterangeStartChanged)="definition.daterangeStartChanged ? definition?.daterangeStartChanged($event) : void()"
            (daterangeEndChanged)="definition.daterangeEndChanged ? definition?.daterangeEndChanged($event) : void()"
            [checked]="definition.checked"
            [customErrorMessage]="definition.customErrorMessage"
            [minText]="definition.minText"
            [maxText]="definition.maxText"
            [hidden]="definition.hidden"
          ></lib-form-field>
        </ng-container>
      </div>
    </fieldset>
  `,
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GroupComponent {
  @Input() class = '';
  @Input() title: string;
  @Input() stackedTitle: boolean;
  @Input() hideTitle: boolean;
  @Input() hide: boolean;
  @Input() disabled: boolean;
  @Input() formGroup?: UntypedFormGroup;
  @Input() formIssues?: FormIssue[];
  @Input() formGroupDefinition?: FieldDefinition[];

  constructor() {}

  void(){}

  fieldIssues(fieldName: string): FormIssue {
    return this.formIssues?.find(({ path }) => path === fieldName);
  }

  get elementClass(): string {
    return `lib-form-group ${this.stackedTitle && 'stacked-title'} ${this.hideTitle && 'hide-title'} ${this.class}`;
  }
  trackByIndex(index: number, item: any) {
    return index
  }
}
