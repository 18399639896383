import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FilterType, FieldSelectOption } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { LocationsLookupService, AttendingPhysiciansLookupService } from '../../../../core/services/lookup';
import { PracticeAnalysisReportService } from './practice-analysis-report.service';

export const PracticeAnalysisReport: Report = {
  id: 'practice-analysis-report',
  label: 'Practice Analysis Report',
  textCriteria: { 'Date Range Type': '', 'Start Date': '', 'End Date': '', 'Locations': '', 'Attending Providers': '', 'Include Detail': '', 'Report Format': ''},
  cleanFilterParams: {
    'locationIds': '', 'attendingProviderIds': ''
  },
  getReportTitle: () => {
    return PracticeAnalysisReport.label + ' ' + PracticeAnalysisReport.textCriteria['Start Date'] + ' - ' + PracticeAnalysisReport.textCriteria['End Date']
      + ' (' + PracticeAnalysisReport.textCriteria['Date Range Type'] + ')';
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Range Type',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-6 ',
          options: [
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Posting Date',
              value: 'P'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            PracticeAnalysisReport.textCriteria['Date Range Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            PracticeAnalysisReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            PracticeAnalysisReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            PracticeAnalysisReport.textCriteria['Locations'] = listText.join(',');
            PracticeAnalysisReport.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          matLabel: 'Attending Provider',
          name: 'attendingProviderIds',
          apiService: injector.get(AttendingPhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Attending Provider',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            PracticeAnalysisReport.textCriteria['Attending Providers'] = listText.join(',');
            PracticeAnalysisReport.cleanFilterParams['attendingProviderIds'] = listValue

          }
        },
        {
          label: 'Include Detail',
          name: 'includeDetail',
          type: 'select',
          options: [
            {
              label: 'Yes',
              value: 'true'
            },
            {
              label: 'No',
              value: 'false'
            }
          ],
          class: 'form-span-3',
          validators: Validators.required,
          selectionChanged: (event) => {
            PracticeAnalysisReport.textCriteria['Include Detail'] = event.source.triggerValue;
          }
        },
        {
          label: 'Report Format',
          name: 'reportFormat',
          class: 'form-span-5',
          type: 'select',
          options: [
            {
              label: 'by attending provider within location',
              value: 'attendingLocation'
            },
            {
              label: 'by attending provider',
              value: 'attending'
            },
            {
              label: 'by location of service',
              value: 'location'
            },
            {
              label: 'by dataset',
              value: 'dataset'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            PracticeAnalysisReport.textCriteria['Report Format'] = event.source.triggerValue;
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(PracticeAnalysisReportService),
      columnDefinitions: [
        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Attending Provider',
          displayKey: 'Attending Provider',
          headerText: 'Attending Provider',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Procedure Code',
          displayKey: 'Code',
          headerText: 'Procedure Code',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Description',
          displayKey: 'Description',
          headerText: 'Description',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Volume',
          displayKey: 'Volume',
          headerText: 'Volume',
          displayOrder: 4,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Amount',
          displayKey: 'Amount',
          headerText: 'Amount',
          displayOrder: 5,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'YTD Volume',
          displayKey: 'YTD Volume',
          headerText: 'YTD Volume',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'YTD Amount',
          displayKey: 'YTD Amount',
          headerText: 'YTD Amount',
          displayOrder: 7,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        }
      ]
    };
  }
};
