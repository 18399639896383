/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationSimpleViewModel } from './locationSimpleViewModel';
import { ProcedureSimpleViewModel } from './procedureSimpleViewModel';
import { ProviderSimpleViewModel } from './providerSimpleViewModel';
import { CodeSimpleViewModel } from './codeSimpleViewModel';
import { ARTransactionDetailSimpleViewModel } from './aRTransactionDetailSimpleViewModel';
import { NationalDrugCodeSimpleViewModel } from './nationalDrugCodeSimpleViewModel';
import { ProcedureDiagnosisSimpleViewModel } from './procedureDiagnosisSimpleViewModel';

export interface ProcedureTransactionSimpleViewModel {
  id?: string;
  serviceFromDate?: string;
  serviceToDate?: string;
  postDate?: string;
  quantity?: number;
  insuranceAuthNumber?: string | null;
  procedureAmount?: number;
  patientBalance?: number;
  insuranceBalance?: number;
  balance?: number;
  paid?: number;
  adjusted?: number;
  claimComment?: string | null;
  procedure?: ProcedureSimpleViewModel;
  nationalDrugCode?: NationalDrugCodeSimpleViewModel;
  modifier1?: CodeSimpleViewModel;
  modifier2?: CodeSimpleViewModel;
  modifier3?: CodeSimpleViewModel;
  modifier4?: CodeSimpleViewModel;
  location?: LocationSimpleViewModel;
  placeOfServiceCode?: CodeSimpleViewModel;
  attendingProvider?: ProviderSimpleViewModel;
  referringProvider?: ProviderSimpleViewModel;
  orderingProvider?: ProviderSimpleViewModel;
  primaryProvider?: ProviderSimpleViewModel;
  supervisingProvider?: ProviderSimpleViewModel;
  diagnoses?: Array<ProcedureDiagnosisSimpleViewModel> | null;
  arTransactionDetails?: Array<ARTransactionDetailSimpleViewModel> | null;
}
