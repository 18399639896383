import { Injectable } from '@angular/core';
import {
  ProviderService,
  ProviderDetailsViewModel,
  ProviderDetailsViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersWrapperService extends BaseWrapperService {
  constructor(private service: ProviderService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1ProviderAllPost(pagedModelRequest?: PagedModelRequest): Observable<ProviderDetailsViewModelPagedModelResponse> {
    return this.service.apiV1ProviderSearchPost(this.getDataSetId(), pagedModelRequest);
  }

  private cache: Observable<string>[] = [];
  apiV1ProviderIdGetFromCache(id: string): Observable<ProviderDetailsViewModel> {
    if (!this.cache[id]) {
      this.cache[id] = this.apiV1ProviderIdGet(id).pipe(shareReplay());
    }
    return this.cache[id];
  }

  apiV1ProviderIdGet(id: string): Observable<ProviderDetailsViewModel> {
    return this.service.apiV1ProviderIdGet(id, this.getDataSetId());
  }

  apiV1ProviderDetailsIdGet(id: string): Observable<ProviderDetailsViewModel> {
    return this.service.apiV1ProviderDetailsIdGet(id, this.getDataSetId());
  }

  apiV1ProviderAddPost(requestBody): Observable<ProviderDetailsViewModel> {
    return this.service.apiV1ProviderAddPost(this.getDataSetId(), requestBody);
  }

  apiV1ProviderPatchIdPatch(id: string, operation): Observable<ProviderDetailsViewModel> {
    return this.service.apiV1ProviderPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1ProviderIdDelete(id: string): Observable<ProviderDetailsViewModel> {
    return this.service.apiV1ProviderIdDelete(id, this.getDataSetId());
  }
}
