import { AfterViewInit } from '@angular/core';
import { Component, Injector, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, concatMap, first, flatMap, map, mergeMap, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ProcedureWrapperService } from 'src/app/core/services/service-wrappers/procedure-wrapper.service';
import { ModifiersLookupService } from '../../../../core/services/lookup';
import { ReportTypesLookupService } from '../../../../core/services/lookup/report-types-lookup.service';
import { RvuTypeLookupService } from '../../../../core/services/lookup/rvu-type-lookup.service';
import { NationalDrugCodeWrapperService } from '../../../../core/services/service-wrappers/national-drug-code-wrapper.service';
import { NationalDrugCodeDialogComponent } from './national-drug-code-dialog/national-drug-code-dialog.component';
import { ProcedureDetailsViewModel } from '../../../../../../projects/data/src/public-api';

@Component({
  selector: 'app-procedures-dialog',
  templateUrl: './procedures-dialog.component.html',
  styleUrls: ['./procedures-dialog.component.scss']
})
export class ProceduresDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  procedureId;
  procedure;
  delete: boolean;
  ndcFormValues;
  formDefinitions: FormGroupDefinition[];
  protected subscriptions: Subscription[] = [];
  viewOnly = false;
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Procedure Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Professional, Technical, Global',
            name: 'procedureComponentType',
            type: 'select',
            options: [
              {
                label: 'Professional',
                value: 'Professional'
              },
              {
                label: 'Technical',
                value: 'Technical'
              },
              {
                label: 'Global',
                value: 'Both'
              }
            ],
            class: 'form-span-5',
            validators: Validators.required
          },
          {
            label: 'NOC',
            name: 'descriptionRequired',
            type: 'checkbox',
            initial: false,
            class: 'form-span-2'
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            initial: true,
            class: 'form-span-2',
            validators: Validators.required
          },
          {
            label: 'Description',
            name: 'description',
            type: 'text',
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Fee',
            name: 'fee',
            type: 'money',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Type Of Service',
            name: 'typeOfService',
            type: 'select',
            options: [
              {
                label: 'Medical Care',
                value: 'MedicalCare'
              },
              {
                label: 'Surgery',
                value: 'Surgery'
              },
              {
                label: 'Consultation',
                value: 'Consultation'
              },
              {
                label: 'Diagnostic X-Ray',
                value: 'DiagnosticXray'
              },
              {
                label: 'Diagnostic Laboratory',
                value: 'DiagnosticLaboratory'
              },
              {
                label: 'Radiation Therapy',
                value: 'RadiationTherapy'
              },
              {
                label: 'Anesthesia',
                value: 'Anesthesia'
              },
              {
                label: 'Assistance At Surgery',
                value: 'AssistanceAtSurgery'
              },
              {
                label: 'Other Medical Services',
                value: 'OtherMedicalServices'
              },
              {
                label: 'Supplies',
                value: 'Supplies'
              },
              {
                label: 'Drugs',
                value: 'Drugs'
              }
            ],
            class: 'form-span-3'
          },
          {
            label: 'Default Modifier 1',
            name: 'defaultModifier1Id',
            type: 'select',
            apiService: this.modifierLookup,
            class: 'form-span-3'
          },
          {
            label: 'Default Modifier 2',
            name: 'defaultModifier2Id',
            type: 'select',
            apiService: this.modifierLookup,
            class: 'form-span-3'
          },
          {
            label: 'Default Modifier 3',
            name: 'defaultModifier3Id',
            type: 'select',
            apiService: this.modifierLookup,
            class: 'form-span-3'
          },
          {
            label: 'Default Modifier 4',
            name: 'defaultModifier4Id',
            type: 'select',
            apiService: this.modifierLookup,
            class: 'form-span-3'
          },
      
          {
            label: 'Reporting Type',
            name: 'reportingTypeId',
            type: 'select',
            apiService: this.reportTypeLookup,
            class: 'form-span-6'
          },
          {
            label: 'RVU Type',
            name: 'rvuType',
            type: 'select',
            options: [
              {
                label: 'Facility',
                value: 'Facility'
              },
              {
                label: 'Non Facility',
                value: 'NonFacility'
              }
            ],
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Effective From',
            name: 'effectiveFrom',
            type: 'date',
            class: 'form-span-3',
            validators: [Validators.required]
          },
          {
            label: 'Effective To',
            name: 'effectiveTo',
            type: 'date',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Send CLIA',
            name: 'sendClia',
            type: 'checkbox',
            initial: false,
            class: 'form-span-3',
          },
          {
            label: 'Send Mammo',
            name: 'sendMammo',
            type: 'checkbox',
            initial: false,
            class: 'form-span-3',
          },
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Insurance Codes',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: '1',
            type: 'label',
            class: 'form-span-2'
          },
          {
            name: 'insuranceCode1',
            type: 'text',
            class: 'form-span-4',
            validators: [Validators.required],
            label: 'Insurance Code 1'
          },
          {
            type: 'empty',
            class: 'form-span-6'
          },
          {
            label: '2',
            type: 'label',
            class: 'form-span-2'
          },
          {
            name: 'insuranceCode2',
            type: 'text',
            class: 'form-span-4'
          },
          {
            type: 'empty',
            class: 'form-span-6'
          },
          {
            label: '3',
            type: 'label',
            class: 'form-span-2'
          },
          {
            name: 'insuranceCode3',
            type: 'text',
            class: 'form-span-4'
          },
          {
            type: 'empty',
            class: 'form-span-6'
          },
          {
            label: '4',
            type: 'label',
            class: 'form-span-2'
          },
          {
            name: 'insuranceCode4',
            type: 'text',
            class: 'form-span-4'
          },
          {
            type: 'empty',
            class: 'form-span-6'
          },
          {
            label: '5',
            type: 'label',
            class: 'form-span-2'
          },
          {
            name: 'insuranceCode5',
            type: 'text',
            class: 'form-span-4'
          },
          {
            type: 'empty',
            class: 'form-span-6'
          }
        ]
      }
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProceduresDialogComponent>,
    private service: ProcedureWrapperService,
    private modifierLookup: ModifiersLookupService,
    private reportTypeLookup: ReportTypesLookupService,
    private rvuTypeLookup: RvuTypeLookupService,
    private ndcService: NationalDrugCodeWrapperService
  ) {
    this.procedureId = data?.procedureId;
    const isEdit = this.procedureId && this.procedureId !== '';
    this.viewOnly = data?.viewOnly;
    this.formDefinitions = this.getFormDefinitions(isEdit);
    this.modifierLookup.bIncludeInactive = false;
    this.modifierLookup.existingValues = [];
  }

  ngOnInit(): void {
    if (this.procedureId && this.procedureId !== '') {
      this.service
        .apiV1ProcedureIdGet(this.procedureId)
        .pipe(first())
        .subscribe((result) => {
          this.procedure = result;
          if (result.defaultModifier1Id) this.modifierLookup.existingValues.push(result.defaultModifier1Id);
          if (result.defaultModifier2Id) this.modifierLookup.existingValues.push(result.defaultModifier2Id);
          if (result.defaultModifier3Id) this.modifierLookup.existingValues.push(result.defaultModifier3Id);
          if (result.defaultModifier4Id) this.modifierLookup.existingValues.push(result.defaultModifier4Id);
          this.setupForm();
        });
    }
    else {
      this.setupForm();
    }
  }

  loadProcedureCode(code) {
    this.formGroup.get('insuranceCode1').patchValue(code);
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    if (this.procedure !==null) {
      this.formGroup.patchValue(this.procedure);
      this.formGroup.get('active').patchValue(!this.procedure?.inactive);
      this.formGroup.get('fee').patchValue(this.procedure?.fee.toFixed(2));
      if (this.procedure?.insuranceCode1 === null) this.formGroup.get('insuranceCode1').patchValue(this.procedure?.code);
      this.formGroup.get('sendClia').patchValue(this.procedure?.sendClia);
      this.formGroup.get('sendMammo').patchValue(this.procedure?.sendMammo);
    }

    this.subscriptions.push(
      this.formGroup.get('code').valueChanges.subscribe((x: any) => {
        this.loadProcedureCode(x);
      })
    );
    this.subscriptions.push(this.formGroup.get('typeOfService').valueChanges.subscribe((x) => {
      if (x === 'Drugs') {
        let ndcInfo
        if (this.procedureId !== null && x === 'Drugs') {
        ndcInfo = {
            procedureId: this.procedureId,
            ndcAmount: this.formGroup.get('fee').value
          };

        }
        else if (this.procedureId === null && x === 'Drugs') {
          ndcInfo = {
            procedureId: null,
            ndcAmount: this.formGroup.get('fee').value
          };
        }
        const dialog = this.dialog.open(NationalDrugCodeDialogComponent, {
          disableClose: true,
          autoFocus: false,
          data: { data: ndcInfo }
        });
        dialog.afterClosed().subscribe((result) => {
          this.ndcFormValues = result

        })
      }
    })
    )
    this.formInitialized = true;
  }

  cancel() {
   this.dialogRef.close();
  }

  //created method to make mergeMap after add or patch procedure easier
  ndcObserve(formData) {
    let ndcObservable: Observable<any>
    delete this.ndcFormValues.isCancel;
    if (this.ndcFormValues.procedureId === null) {
      this.ndcFormValues.procedureId = formData.id
    }
    if (this.ndcFormValues.id === undefined) {
      delete this.ndcFormValues.id
      this.ndcService.apiV1NationalDrugCodeAddPost(this.ndcFormValues).pipe(map((x: any) => x)).pipe(map((x: any) => x)).pipe(take(1))
        .subscribe((response) => {
          this.dialogRef.close(response)
          this.notificationService.success("NDC Code Saved")
        },
          (err) => {
            this.notificationService.error("Error saving NDC Code")
            this.dialogRef.close()
          }
        );
    }
    else if (this.ndcFormValues.id !== undefined) {
      this.ndcFormValues['ndcAmount'] = formData['fee'];
      this.ndcService.apiV1NationalDrugCodePatchIdPatch(this.ndcFormValues?.id, compare({}, this.ndcFormValues)).pipe(map((x: any) => x)).pipe(take(1))
        .subscribe((response) => {
          this.dialogRef.close(response)
          this.notificationService.success("NDC Code Saved")
        },
          (err) => {
            this.notificationService.error("Error saving NDC Code")
            this.dialogRef.close()
          }
        );
    }

  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;

      const formData = {
        code: this.formGroup.get('code').value,
        description: this.formGroup.get('description').value,
        procedureComponentType: this.formGroup.get('procedureComponentType').value,
        descriptionRequired: this.formGroup.get('descriptionRequired').value,
        fee: Number(this.formGroup.get('fee').value?.replace(/[^0-9\.-]+/g,"")),
        effectiveFrom: this.formGroup.get('effectiveFrom').value,
        effectiveTo: this.formGroup.get('effectiveTo').value,
        rvuType: this.formGroup.get('rvuType').value,
        typeOfService: this.formGroup.get('typeOfService').value,
        reportingTypeId: this.formGroup.get('reportingTypeId').value,
        defaultModifier1Id: this.formGroup.get('defaultModifier1Id').value,
        defaultModifier2Id: this.formGroup.get('defaultModifier2Id').value,
        defaultModifier3Id: this.formGroup.get('defaultModifier3Id').value,
        defaultModifier4Id: this.formGroup.get('defaultModifier4Id').value,
        inactive: !(this.formGroup.get('active').value),
        insuranceCode1: this.formGroup.get('insuranceCode1').value,
        insuranceCode2: this.formGroup.get('insuranceCode2').value,
        insuranceCode3: this.formGroup.get('insuranceCode3').value,
        insuranceCode4: this.formGroup.get('insuranceCode4').value,
        insuranceCode5: this.formGroup.get('insuranceCode5').value,
        sendClia: (this.formGroup.get('sendClia').value),
        sendMammo: (this.formGroup.get('sendMammo').value),
      };

      let saveObservable: Observable<any>;
      let ndcObservable: Observable<any>
      let notification;
      if (this.formGroup.get('typeOfService').value === 'Drugs' &&  this.ndcFormValues === undefined) {
        this.notificationService.error("If Type Of Service is Drugs then NDC info is required!") //added to ensure user does not create drug type proc code without ndc info
        let ndcInfo
        if (this.procedureId !== null) {
          ndcInfo = {
            procedureId: this.procedureId,
            ndcAmount: this.formGroup.get('fee').value
          };

        }
        else {
          ndcInfo = {
            procedureId: null,
            ndcAmount: this.formGroup.get('fee').value
          };
        }
        const dialog = this.dialog.open(NationalDrugCodeDialogComponent, {
          disableClose: true,
          autoFocus: false,
          data: { data: ndcInfo }
        });
        this.saving = false;
      }
      else {
        if (this.procedureId) {
          notification = 'Procedure Code Updated';
          saveObservable = this.service
            .apiV1ProcedurePatchIdPatch(this.procedureId, compare({}, formData))
            .pipe(map((x: ProcedureDetailsViewModel) => x));
        } else {
          notification = 'Procedure Code Added';
          saveObservable = this.service.apiV1ProcedureAddPost(formData).pipe(map((x: ProcedureDetailsViewModel) => x ));
        }
        if (this.formGroup.get('typeOfService').value !== 'Drugs') {
          saveObservable
            .pipe(take(1))
            .subscribe(
              (response) => {
                this.saving = false;
                this.dialogRef.close(response);
                this.notificationService.success(notification);
              },
          )
          if (this.procedureId !== null && this.procedureId !== undefined && this.ndcFormValues !== undefined && this.ndcFormValues.isCancel !== true) {
            this.ndcService.apiV1NationalDrugCodeByProcedureIdIdGet(this.procedureId).pipe(mergeMap((x) => {
              if (x !== null && x !== undefined) {
                return this.ndcService.apiV1NationalDrugCodeIdDelete(x?.id)
              }
            })).subscribe((x) => {
              this.notificationService.success("NDC Code has been removed")
            },
              (err) => {
                this.notificationService.error("issue removing NDC Code")
              }
            )
          }
        }
        else if (this.formGroup.get('typeOfService').value === 'Drugs' && this.ndcFormValues !== undefined) {

          saveObservable.pipe(mergeMap(async (x) => this.ndcObserve(x) ))
            .subscribe((x) => {
              this.notificationService.success(notification);
              return this.ndcFormValues
            },
              (err) => {
                this.notificationService.error('Error saving NDC Code');
              }
          )
        }
      }
    

    }
  }
}
