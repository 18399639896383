<ng-container *ngIf="!isLoading; else loading">
  <div class="reminder-list__body">
    <div class="reminder-list__reminders">
      <ng-container *ngIf="reminders?.length > 0; else noReminders">
        <div *ngFor="let reminder of displayReminders; trackBy: trackById" class="reminder-list__reminder">
          <div class="reminder-list__context-menu">
            <lib-overflow-menu
              [options]="overflowMenuOptions"
              (optionClicked)="overflowMenuClicked($event, reminder)"
            ></lib-overflow-menu>
          </div>
          <div class="h6 mb-2" *ngIf="reminder.account">
            {{ reminder?.account?.accountNumberDisplay }} -
            {{ reminder?.account?.name }}
          </div>
          <div class="h6 mb-2" *ngIf="reminder.patient">
            {{ reminder?.patient?.accountNumberDisplay }} - {{ reminder?.patient?.firstName }}
            {{ reminder?.patient?.lastName }}
          </div>
          <div class="h6 h6--semi-bold mb-2">{{ reminder.message }}</div>
          <span class="reminder-list__reminder-date">
            {{ reminder.scheduledDate | date : 'M/d/y' }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="loading">
    <div>
      <h5>Loading Reminders</h5>
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
  </div>
</ng-template>
<ng-template #noReminders>
  <div class="reminder-list__no-reminders">
    <div>
      <h5>No Active Reminders</h5>
    </div>
  </div>
</ng-template>
