import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import {
  LocationsLookupService,
  AttendingPhysiciansLookupService,
  PhysiciansLookupService
} from '../../../../core/services/lookup';
import { PaymentAllocationReportService } from './payment-allocation-report.service';

const dateFormat = new Intl.DateTimeFormat('en-us');
const dateDisplayPipe: DateDisplayPipe = new DateDisplayPipe('en-us');

export const PaymentAllocationReport: Report = {
  id: 'payment-allocation',
  label: 'Payment Allocation Report',
  textCriteria: {
    'Start Date': '',
    'End Date': '',
    'Batch Range Start': '',
    'Batch Range End': '',
    'Payment Type': '',
    'Providers': '',
    'Locations': ''
  },
  cleanFilterParams: {
    'locationIds': '', 'providerIds': ''
  },
  getReportTitle: () => {
    return (
      PaymentAllocationReport.label +
      ' ' +
      PaymentAllocationReport.textCriteria['Start Date'] +
      ' - ' +
      PaymentAllocationReport.textCriteria['End Date'] 
    );
  },
  getSearchCriteria: (injector: Injector) => [
    {
      controls: [
        {
          label: 'Posted Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            PaymentAllocationReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            PaymentAllocationReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        { 
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: 'Batch Range Start',
          name: 'batchRangeStart',
          class: 'form-span-6',
          type: 'number',
          min: 0,
          selectionChanged: (event) => {
            PaymentAllocationReport.textCriteria['Batch Range Start'] = event;
          }
        },
        {
          label: 'Batch Range End',
          name: 'batchRangeEnd',
          class: 'form-span-6',
          type: 'number',
          min: 0,
          selectionChanged: (event) => {
            PaymentAllocationReport.textCriteria['Batch Range End'] = event;
          }
        },
        {
          matLabel: 'Provider',
          name: 'providerIds',
          apiService: injector.get(PhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-12 multi-input',
          placeholder: 'Provider',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            PaymentAllocationReport.textCriteria['Providers'] = listText.join(',');
            PaymentAllocationReport.cleanFilterParams['attendingProviderIds'] = listValue

          }
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-12 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            PaymentAllocationReport.textCriteria['Location'] = listText.join(',');
            PaymentAllocationReport.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          label: 'Payment Type',
          name: 'paymentType',
          class: 'form-span-6',
          type: 'select',
          multiple: false,
          validators: Validators.required,
          options: ['Both', 'Insurance', 'Patient'],
          selectionChanged: (event) => {
            PaymentAllocationReport.textCriteria['Payment Type'] = event.source.triggerValue;
          },
        },
        
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(PaymentAllocationReportService),
      columnDefinitions: [
        {
          id: 'record_level_desc',
          displayKey: 'record_level_desc',
          headerText: 'Level',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },{
          id: 'payer_name',
          displayKey: 'payer_name',
          headerText: 'Payer / Patient',
          displayOrder: 0,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'payment_date',
          displayKey: 'payment_date',
          headerText: 'Payment Date',
          displayOrder: 1,
          columnSize: ColumnSizes.date,
          sortable: true,
          filterable: true,
          formatter: (x) => { let d = dateDisplayPipe.transform(x); return d == "--" ? "" : d;  }
        },
        {
          id: 'patient_name',
          displayKey: 'patient_name',
          headerText: 'Patient Name',
          displayOrder: 2,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true,
        },
        {
          id: 'procedure_amount',
          displayKey: 'procedure_amount',
          headerText: 'Procedure Amount',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: (x) => { return !x ? "" : moneyFormat.format(x); }
        },
        {
          id: 'payment_amount',
          displayKey: 'payment_amount',
          headerText: 'Payment',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'balance',
          displayKey: 'balance',
          headerText: 'Balance',
          displayOrder: 5,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'service_date',
          displayKey: 'service_date',
          headerText: 'Service Date',
          displayOrder: 6,
          columnSize: ColumnSizes.date,
          sortable: true,
          filterable: true,
          formatter: (x) => { let d = dateDisplayPipe.transform(x); return d == "--" ? "" : d;  }
        },
        {
          id: 'procedure_code',
          displayKey: 'procedure_code',
          headerText: 'Procedure Code',
          displayOrder: 7,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'reference_number',
          displayKey: 'reference_number',
          headerText: 'Ref #',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        }
      ]
    }
  },
  serverPdf: true,
  hasDetail: true,
  reportDownloadFormat : 
  {
    //main headers for report
    headers: [
        {
            header: "Payer / Patient",
            alignment: "L"
        },
        {
            header: "Payment Date",
            alignment: "L"
        },
        {
            header: "Procedure Amount",
            alignment: "R"
        },
        {
            header: "Balance",
            alignment: "R"
        },
        {
            header: "Payment",
            alignment: "R"
        },
        {
            header: "Ref #",
            alignment: "L"
        }
    ],
    records: [
        
          {
            level: 5,
            bold: true,
            spaceBefore: 5,
            spaceAfter: 0,
            pageBreakBefore: true,
            columns: [
                {
                    name:"payer_name",
                    "span": 2
                },
                {
                    name:"procedure_amount"
                },
                {
                    name:"balance"
                },
                {
                    name:"payment_amount"
                }
            ]
        },
        {
            level: 4,
            bold: true,
            spaceBefore: 5,
            spaceAfter: 0,
            pageBreakBefore: true,
            columns: [
                {
                    name:"payment_date",
                    "span": 4
                },
                {
                    name:"payment_amount"
                }
            ]
        },
        {
            level: 3,
            bold: true,
            spaceBefore: 2,
            spaceAfter: 2,
            hasSubTable: true,
            borderTop: 1,
            headers: {
                paddingLeft: 5,
                titles: [
                    "Patient Name",
                    "DOS",
                    "Procedure",
                    "Charge",
                    "Disb Amt"  
                ],
                borderBottom: 1
            },
            columns: [
                {
                    name:"payer_name"
                },
                {
                    name:"payment_date"
                },
                {
                    name:"procedure_amount"
                },
                {
                    name:"balance"
                },
                {
                    name:"payment_amount"
                },
                {
                  name:"reference_number"
              }
            ]
        },
        {
            level: 2,
            spaceBefore: 5,
            columns: [
                {
                    name:"patient_name",
                    span: 3,
                    paddingLeft: 5
                },
                {
                    name:"procedure_amount"
                },
                {
                    name:"payment_amount"
                }
            ],
        },
        {
            level: 1,
            spaceAfterLast: 5,
            columns: [
                {
                    name:"dummy"
                },
                {
                    name:"service_date"
                },
                {
                    name:"procedure_code"
                },
                {
                    name:"procedure_amount"
                },
                {
                    name:"payment_amount"
                }
            ]
        }
    ]
}
};
