/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BatchReportDetailsViewModel } from './batchReportDetailsViewModel';

export interface BatchReportViewModel {
  batchId?: string;
  batchNumber?: number;
  batchStatus?: string | null;
  patientId?: string;
  accountNumber?: number;
  visitId?: string;
  referenceNumber?: number;
  arTransactionHoldingId?: string;
  insuranceCarrierId?: string;
  insuranceId?: string;
  insuranceCarrierCode?: string | null;
  patientName?: string | null;
  procedureAmount?: number;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  checkNumber?: string | null;
  balance?: number;
  isExpanded?: boolean;
  postingDate?: string | null;
  batchReportDetails?: Array<BatchReportDetailsViewModel> | null;
}
