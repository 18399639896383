/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityDependentViewModel } from './eligibilityDependentViewModel';
import { EligibilityReferenceViewModel } from './eligibilityReferenceViewModel';
import { EligibilityTimePeriodViewModel } from './eligibilityTimePeriodViewModel';
import { EligibilityValidationViewModel } from './eligibilityValidationViewModel';
import { EligibilityBenefitsViewModel } from './eligibilityBenefitsViewModel';

export interface EligibilitySubscriberViewModel {
  id?: string;
  subscriberLastOrgName?: string | null;
  subscriberFirstName?: string | null;
  subscriberMiddleName?: string | null;
  subscriberIdentificationQualifierCode?: string | null;
  subscriberIdentificationQualifierName?: string | null;
  subscriberIdentificationCode?: string | null;
  subscriberAddressLine1?: string | null;
  subscriberAddressLine2?: string | null;
  subscriberCity?: string | null;
  subscriberState?: string | null;
  subscriberPostalCode?: string | null;
  subscriberDateOfBirth?: string;
  subscriberGenderCode?: string | null;
  subscriberInsuredResponseCode?: string | null;
  subscriberInsuredRelationshipCode?: string | null;
  subscriberInsuredRelationshipName?: string | null;
  subscriberInsuredMaintenanceTypeCode?: string | null;
  subscriberInsuredMaintenanceReasonCode?: string | null;
  dependent?: EligibilityDependentViewModel;
  validations?: Array<EligibilityValidationViewModel> | null;
  eligibilityInformation?: Array<EligibilityBenefitsViewModel> | null;
  timePeriods?: Array<EligibilityTimePeriodViewModel> | null;
  references?: Array<EligibilityReferenceViewModel> | null;
}
