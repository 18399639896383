import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { PhysiciansWrapperService } from '../service-wrappers/physicians-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class PhysiciansLookupService extends ApiAutoCompleteService {
  constructor(private service: PhysiciansWrapperService) {
    super();
    this.dynamicSearch = true;
  }

  existingValues: string [] = [];

  physicianEmpty$ = this.service
    .apiV1PhysicianAllPost({
      generalSearch: '',
      pageSize: 9999
    })
    .pipe(shareReplay(1));

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.providerNumber}-${result.firstName || ''} ${result.middleName || ''} ${result.lastName || ''}, ${result.address1 || ''}, ${result.city || ''} ${result.stateCode || ''} ${result.zip || ''} `,
      inactive: result.inactive,
      shortLabel: result.providerNumber
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.physicianEmpty$.pipe(
        map((results) => results.data.map(this.mapRow).filter((x: any) => !x.inactive || this.existingValues.includes(x.value)))
      );
    }

    return this.service
      .apiV1PhysicianAllPost({
        generalSearch: value,
        pageSize: 100
      })
      .pipe(map((results) => results.data.map(this.mapRow).filter((x: any) => !x.inactive || this.existingValues.includes(x.value))));
  }
  private cache: Observable<string>[] = [];
  load(id: string): Observable<FieldSelectOption> {
    if (!this.cache[id]) {
      this.cache[id] = this.service.apiV1PhysicianIdGet(id).pipe(map(this.mapRow)).pipe(shareReplay());
    }

    return this.cache[id];
  }
}
