import { Injectable } from '@angular/core';
import {
  LocationService,
  LocationDetailsViewModel,
  LocationDetailsViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsWrapperService extends BaseWrapperService {
  constructor(private service: LocationService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1LocationAllPost(pagedModelRequest?: PagedModelRequest): Observable<LocationDetailsViewModelPagedModelResponse> {
    return this.service.apiV1LocationAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1LocationIdGet(id: string): Observable<LocationDetailsViewModel> {
    return this.service.apiV1LocationIdGet(id, this.getDataSetId());
  }

  apiV1LocationDetailsIdGet(id: string): Observable<LocationDetailsViewModel> {
    return this.service.apiV1LocationDetailsIdGet(id, this.getDataSetId());
  }

  private cache: Observable<string>[] = [];
  apiV1LocationIdGetFromCache(id: string): Observable<LocationDetailsViewModel> {
    if (!this.cache[id]) {
      this.cache[id] = this.apiV1LocationIdGet(id).pipe(shareReplay());
    }
    return this.cache[id];
  }

  apiV1LocationAddPost(requestBody): Observable<LocationDetailsViewModel> {
    return this.service.apiV1LocationAddPost(this.getDataSetId(), requestBody);
  }

  apiV1LocationPatchIdPatch(id: string, operation): Observable<LocationDetailsViewModel> {
    return this.service.apiV1LocationPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1LocationIdDelete(id: string): Observable<LocationDetailsViewModel> {
    return this.service.apiV1LocationIdDelete(id, this.getDataSetId());
  }
}
