/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OutgoingInsuranceClaimsViewModel {
  referenceNumber?: number;
  patientId?: string;
  accountNumber?: number;
  firstName?: string | null;
  lastName?: string | null;
  visitId?: string;
  serviceDate?: string | null;
  postingDate?: string | null;
  insuranceName?: string | null;
  billedAmount?: number | null;
  sequence?: string | null;
  claimType?: string | null;
  insuranceId?: string;
}
