import { Injectable } from '@angular/core';
import { GuarantorDetailsViewModel, PatientDetailsViewModel } from 'data';
import { BaseFormComponent } from './base-form.component';
import { DatePipe } from '@angular/common';

@Injectable()
export class BaseInsuranceFormComponent extends BaseFormComponent {
  baseGuarantor: GuarantorDetailsViewModel;
  basePatient: PatientDetailsViewModel
  applyBasePatientDemographics(formGroupName: string) {
    return this.getControl(formGroupName, 'insuredRelation').valueChanges.subscribe((relation) => {
    
      const demoInformation = this.getGroup(formGroupName);
      if (relation.toLowerCase() === 'self' && this.basePatient) {
        
        demoInformation.patchValue({
          insuredFirstName: this.basePatient.firstName,
          insuredMiddleName: this.basePatient.middleName,
          insuredLastName: this.basePatient.lastName,
          insuredSuffix: this.basePatient.suffix,
          insuredSex: this.basePatient.sex,
          insuredBirthDate: new DatePipe('en-US').transform(this.basePatient.birthDate, 'MM/dd/yyyy')
        });
      } else if (relation.toLowerCase() === 'spouse' && this?.baseGuarantor?.emergencyContactRelation?.toLowerCase() === 'spouse') {
        demoInformation.patchValue({
          insuredFirstName: this?.baseGuarantor?.emergencyContactFirstName,
          insuredMiddleName: '',
          insuredLastName: this?.baseGuarantor?.emergencyContactLastName,
          insuredSuffix: '',
          insuredSex: '',
          insuredBirthDate: ''
        });
      } else {
        demoInformation.patchValue({
          insuredFirstName: '',
          insuredMiddleName: '',
          insuredLastName: '',
          insuredSuffix: '',
          insuredSex: '',
          insuredBirthDate: ''
        });
      }
      
    });
  }

  applyBaseGuarantorDirect(formGroupName, sameAsGuarantor) {
      const guarantorContact = this.baseGuarantor;
      const contactInformation = this.getGroup(formGroupName);
      if (sameAsGuarantor) {
        contactInformation.patchValue({
          insuredAddress1: guarantorContact.address1,
          insuredAddress2: guarantorContact.address2,
          insuredCity: guarantorContact.city,
          insuredStateCode: guarantorContact.stateCode,
          insuredZip: guarantorContact.zip,
          insuredCountryCode: guarantorContact.countryCode,
          insuredPhone: guarantorContact?.mobilePhone || guarantorContact.homePhone,
          insuredEmail: guarantorContact.email
        });

        contactInformation.disable({ onlySelf: true, emitEvent: false });

        this.getControl(formGroupName, 'useGuarantorContactInfo').enable({
          onlySelf: true,
          emitEvent: false
        });
      } else {
        contactInformation.enable({ onlySelf: true, emitEvent: false });
      }
      this.getControl(formGroupName, 'useGuarantorContactInfo').setValue(sameAsGuarantor);
      
  }
}
