<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<div class="sub-page-layout">
  <ng-container *ngIf="useSideBar; else noSidebar">
    <nav>
      <div class="sub-page-layout__toggle">
        <button mat-icon-button color="primary" (click)="sideNav.toggle()">
          <mat-icon>view_sidebar</mat-icon>
        </button>
      </div>
      <div *ngIf="useNav">
        <ng-content select="[navBar]"></ng-content>
      </div>
    </nav>
    <mat-sidenav-container>
      <mat-sidenav [class]="setSideNavWidth()" #sideNav mode="side" opened>
        <ng-content select="[sideBar]"></ng-content>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="sub-page-layout__content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</div>
<ng-template #noSidebar>
  <nav>
    <div>
      <ng-content select="[navBarNoSidebar]"></ng-content>
    </div>
  </nav>
  <mat-sidenav-container>
    <mat-sidenav-content>
      <div class="sub-page-layout__content">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>
