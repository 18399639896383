<lib-sub-page-layout>
  <ng-container navBar> </ng-container>
  <ng-container sideBar>
    <div class="implementation__steps-container" *ngIf="implementation$ | async">
      <div class="implementation__progress-bar">
        <mat-progress-bar class="implementation__bar" color="accent" [value]="progressPercentage"></mat-progress-bar>
      </div>
      <ng-container *ngFor="let detail of implementationDetails$ | async; trackBy: trackById">
        <div *ngIf="detail.status !== 'NotStarted'">
          <a
            [routerLink]="'/implementation/' + detail.implementationStepCode"
            routerLinkActive="implementation__current-step"
          >
            <div class="implementation__step">
              <span
                *ngIf="detail.status === 'Complete'"
                class="material-icons implementation__status-icon implementation__mat-icon-check-circle"
                title="Complete"
                >check_circle</span
              >
              <span
                *ngIf="detail.status === 'InProgress'"
                class="material-icons implementation__status-icon implementation__mat-icon-change-circle"
                title="In Progress"
                >change_circle</span
              >
              <span class="implementation__step-name">{{ detail.implementationStepName }}</span>
            </div>
          </a>
        </div>
        <div *ngIf="detail.status === 'NotStarted'" class="implementation__step">
          <span class="material-icons implementation__status-icon implementation__mat-icon-circle" title="Not Started"
            >circle</span
          >
          <span class="implementation__step-name">{{ detail.implementationStepName }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="implementation__outlet">
    <router-outlet #o="outlet"></router-outlet>
  </div>
</lib-sub-page-layout>
