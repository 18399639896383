/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CreditBalanceViewModel {
  datasetId?: string;
  patientId?: string;
  lastName?: string | null;
  firstName?: string | null;
  visitId?: string;
  balance?: number;
  accountNumber?: number;
  referenceNumber?: number;
  serviceDate?: string;
  transactionDate?: string;
  insuranceNumber?: string | null;
  insuranceName?: string | null;
  holdStatus?: string | null;
  pendingRefundAmount?: number;
}
