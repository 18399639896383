import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { DiagnosisCodesLookupService, ProcedureCodesLookupService } from '@core/services/lookup';
import { UserLookupService } from '@core/services/lookup/user-lookup.service';
import { RefundsReportService } from './refunds-report.service';

export const RefundsReport: Report = {
  id: 'refunds-report',
  label: 'Refunds Report',
  textCriteria: { 'Start Date': '', 'End Date': '', 'Users': '', 'Minimum Amount': '', 'Maximum Amount': ''},
  cleanFilterParams: { 'userIds': ''},
  getReportTitle: () => {
    return RefundsReport.label + ' ' + RefundsReport.textCriteria['Start Date'] + ' - ' + RefundsReport.textCriteria['End Date'];
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Refund Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            RefundsReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            RefundsReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }  
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Users',
          name: 'userIds',
          apiService: injector.get(UserLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Users',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            RefundsReport.textCriteria['Users'] = listText.join(',');
            RefundsReport.cleanFilterParams['userIds'] = listValue
          },
        },
        {
          label: 'Minimum Amount',
          name: 'minRefundAmount',
          class: 'form-span-6',
          type: 'number',
          min: 0,
          selectionChanged: (event) => {
            RefundsReport.textCriteria['Minimum Amount'] = event;
          }
        },
        {
          label: 'Maximum Amount',
          name: 'maxRefundAmount',
          class: 'form-span-6',
          type: 'number',
          min: 0,
          selectionChanged: (event) => {
            RefundsReport.textCriteria['Maximum Amount'] = event;
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(RefundsReportService),
      columnDefinitions: [
        {
          id: 'account_number',
          displayKey: 'account_number',
          headerText: 'Account Number',
          sortable: true,
          filterable: true,
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
        },
        {
          id: 'patient_name',
          displayKey: 'patient_name',
          headerText: 'Patient Name',
          sortable: true,
          displayOrder: 1,
          columnSize: ColumnSizes.wide,
          filterable: true,
        },
        {
          id: 'reference_number',
          displayKey: 'reference_number',
          headerText: 'Visit',
          sortable: true,
          displayOrder: 2,
          columnSize: ColumnSizes.narrow,
          filterable: true,
        },
        {
          id: 'refund_date',
          displayKey: 'refund_date',
          headerText: 'Refund Date',
          sortable: true,
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          filterable: true,
        },
        {
          id: ' refund_amount',
          displayKey: 'refund_amount',
          headerText: 'Amount',
          sortable: true,
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          formatter: moneyFormat.format,
          filterable: true,
        },
        {
          id: 'created_by',
          displayKey: 'created_by',
          headerText: 'User',
          sortable: true,
          displayOrder: 5,
          columnSize: ColumnSizes.normal,
          filterable: true,
        },
        {
          id: ' payee_name',
          displayKey: 'payee_name',
          headerText: 'Payee Name',
          sortable: true,
          displayOrder: 6,
          columnSize: ColumnSizes.wide,
          filterable: true,
        },
        {
          id: 'payee_address1',
          displayKey: 'payee_address1',
          headerText: 'Address 1',
          sortable: true,
          displayOrder: 7,
          columnSize: ColumnSizes.wide,
          filterable: true,
        },
        {
          id: 'payee_address2',
          displayKey: 'payee_address2',
          headerText: 'Address 2',
          sortable: true,
          displayOrder: 8,
          columnSize: ColumnSizes.normal,
          filterable: true,
        },
        {
          id: 'payee_city',
          displayKey: 'payee_city',
          headerText: 'City',
          displayOrder: 9,
          columnSize: ColumnSizes.normal,
          filterable: true,
        },
        {
          id: 'payee_state',
          displayKey: 'payee_state',
          headerText: 'State',
          sortable: true,
          displayOrder: 10,
          columnSize: ColumnSizes.narrow,
          filterable: true,
        },
        {
          id: 'payee_zip',
          displayKey: 'payee_zip',
          headerText: 'Zip',
          sortable: true,
          displayOrder: 11,
          columnSize: ColumnSizes.narrow,
          filterable: true,
        },
        {
          id: 'notes',
          displayKey: 'notes',
          headerText: 'Notes',
          sortable: false,
          displayOrder: 12,
          columnSize: ColumnSizes.extraWide,
          filterable: true,
        },
        {
          id: 'memo',
          displayKey: 'memo',
          headerText: 'Memo',
          sortable: false,
          displayOrder: 13,
          columnSize: ColumnSizes.extraWide,
          filterable: true,
        }
      ]
    };
  }
};
