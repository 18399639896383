/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatientInsuranceIntegrationViewModel {
  insuranceLevel?: string | null;
  insuranceType?: string | null;
  policyNumber?: string | null;
  groupNumber?: string | null;
  planName?: string | null;
  insuredRelation?: string | null;
  insuredLastName?: string | null;
  insuredFirstName?: string | null;
  insuredMiddleName?: string | null;
  insuredSuffix?: string | null;
  insuredBirthDate?: string;
  insuredGender?: string | null;
  useGuarantorContactInfo?: boolean;
  insuredAddress1?: string | null;
  insuredAddress2?: string | null;
  insuredCity?: string | null;
  insuredStateCode?: string | null;
  insuredZip?: string | null;
  insuredCountryCode?: string | null;
  insuredPhone?: string | null;
  policyEffectiveFrom?: string | null;
  policyEffectiveTo?: string | null;
  acceptAssignment?: boolean;
  carrierCode?: string | null;
  carrierName?: string | null;
  carrierAddress1?: string | null;
  carrierAddress2?: string | null;
  carrierCity?: string | null;
  carrierStateCode?: string | null;
  carrierZip?: string | null;
  carrierCountryCode?: string | null;
  carrierPhoneNumber?: string | null;
  carrierType?: string | null;
  payerId?: string | null;
}
