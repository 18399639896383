/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ARTransactionDetailRemarkHoldingViewModel } from './aRTransactionDetailRemarkHoldingViewModel';
import { CodeViewModel } from './codeViewModel';
import { ARTransactionDetailEobHoldingSimpleViewModel } from './aRTransactionDetailEobHoldingSimpleViewModel';

export interface ARTransactionProcedureViewModel {
  eobCodes?: Array<ARTransactionDetailEobHoldingSimpleViewModel> | null;
  remarkCodes?: Array<ARTransactionDetailRemarkHoldingViewModel> | null;
  datasetId?: string | null;
  procedureTransactionId?: string;
  serviceDate?: string | null;
  procedureCode?: string | null;
  procedureAmount?: number;
  procedureBalance?: number;
  arTransactionDetailHoldingId?: string | null;
  arTransactionDetailId?: string | null;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  coinsuranceAmount?: number;
  copayAmount?: number;
  interestAmount?: number;
  quantity?: number | null;
  isReversed?: boolean;
  modifier?: CodeViewModel;
}
