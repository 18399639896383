import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProcedureTransactionDetailsViewModel, UserDetailsViewModel } from 'data';
import { CustomPaginator } from '../../components/custom-paginator';

@Component({
  selector: 'lib-procedure-details',
  templateUrl: './procedure-details.component.html',
  styleUrls: ['./procedure-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatPaginatorIntl, useClass: CustomPaginator}],
})
export class ProcedureDetailsComponent implements OnInit {
  @Input()
  procedure: ProcedureTransactionDetailsViewModel;

  @Input()
  procedureNumber: number;

  @Input()
  viewOnly: boolean;

  @Input()
  userInfo: UserDetailsViewModel

  @Input()
  diagnoses: any[];

  @Output() editProcedure: EventEmitter<string> = new EventEmitter<string>();
  @Output() reverseProcedure: EventEmitter<string> = new EventEmitter<string>();
  @Output() billProcedureChange: EventEmitter<any> = new EventEmitter<any>();

  procedureTabActive = true;
  showAdditionalFields = false;
  procedureNotes = '';
  noteColor = 'blue';
  transactionsAuth = 'None';
  length: number;
  pageIndex: number;
  page: number;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  dataSource;
  currentPayment;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.transactionsAuth = this.userInfo?.userPermissions.find(x => x.permissionType == "Transactions")?.accessType;
    this.getProcedureNotes();

    this.dataSource = new MatTableDataSource();
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.length = this.procedure?.arTransactionDetails.length;
    this.pageIndex = 1;
    this.currentPayment = this.procedure?.arTransactionDetails[0];
  }

  getProcedureNotes() {
    if (this.procedure?.isReversed) {
      this.procedureNotes = this.procedure?.createdBy === 'Reversal' ? 'REVERSAL' : 'REVERSED';
      this.noteColor = 'red';
    } else if (this.procedure?.currentlyBilled) {
      this.procedureNotes = 'Billing Pending for ' + this.procedure.currentlyBilledCarriers;
    } else if (this.procedure?.previouslyBilled) {
      this.procedureNotes = 'BILLED'
      this.noteColor = 'green';
    }
  }

  billProcedureChanged($event) {
    this.billProcedureChange.emit({ procedureNumber: this.procedureNumber, bill: $event.checked });
  }

  formatDrugCode(code: string) {
    if (code.length == 11) {
      return code.substring(0, 5) + '-' + code.substring(5, 9) + '-' + code.substring(9);
    }
    else if (code.length == 10) {
      return code.substring(0, 5) + '-0' + code.substring(5, 8) + '-' + code.substring(8);
    }
    else {
      return code;
    }
  }

  toggleActiveTab(tab) {
    if (tab === 'payment') {
      this.procedureTabActive = false;
    } else {
      this.procedureTabActive = true;
    }
  }

  showFields(value: boolean) {
    this.showAdditionalFields = value;
  }

  viewBatch(batch) {
    if (this.transactionsAuth !== 'None') {
      sessionStorage.setItem('batchNumber', batch?.batchNumber?.toString());
      sessionStorage.setItem('batchId', batch?.id);
      this.router.navigateByUrl(`/transactions/batches/${batch?.batchNumber}/view`);
    }
  }

  handlePageEvent(event: PageEvent) {
    this.page = event.pageIndex;
    this.pageIndex = event.pageIndex + 1;
    this.currentPayment = this.procedure?.arTransactionDetails[this.page];
  }
}
