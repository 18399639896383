import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class PlacesOfServiceCodesLookupService extends ApiAutoCompleteService {
  codeKey = 'PlaceOfServiceCode';
  constructor(private service: CodeWrapperService) {
    super();
    this.dynamicSearch = true;
  }

  placeOfServiceCodesEmpty$ = this.service
    .apiV1CodeCodetypePost(this.codeKey, {
      generalSearch: '',
      pageSize: 9999, 
      sortColumn: 'code'
    })
    .pipe(shareReplay(1));

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.code} - ${result.name}`,
      shortLabel: result.code
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.placeOfServiceCodesEmpty$.pipe(map((results) => results.data.map(this.mapRow)));
    }
    return this.service
      .apiV1CodeCodetypePost(this.codeKey, {
        generalSearch: value, pageSize: 100, sortColumn: 'code'
      })
      .pipe(map((results) => results.data.map(this.mapRow)));
  }

  load(id: string): Observable<FieldSelectOption> {
    if (id !== '') {
      return this.service.apiV1CodeCodetypeIdGet(this.codeKey, id).pipe(map(this.mapRow));
    }
  }
}
