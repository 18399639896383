import { Component, Injector, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  FormGroupDefinition,
  DialogContent,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  GridConfiguration,
  OrderByPipe,
  CustomValidators
} from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ProvidersWrapperService } from 'src/app/core/services/service-wrappers/providers-wrapper.service';
import { ProviderLocationsWrapperService } from 'src/app/core/services/service-wrappers/providerLocations-wrapper.service';
import { CountryCodesLookupService, StateCodesLookupService } from 'src/app/core/services/lookup';
import { LocationDetailsViewModel } from '../../../../../../projects/data/src/public-api';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ProviderLocationsEntryDialogComponent } from 'src/app/features/files/providerLocations/provider-locations-entry-dialog/provider-locations-entry-dialog.component';
import { ProvidersLookupService } from 'src/app/core/services/lookup/providers-lookup.service';
import { MailingAddressUtility } from '../../../../core/utilities/function.utils';

@Component({
  selector: 'app-providers-dialog',
  templateUrl: './providers-dialog.component.html',
  styleUrls: ['./providers-dialog.component.scss']
})
export class ProvidersDialogComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<LocationDetailsViewModel>;
  @ViewChild('paginator') paginator: MatPaginator;
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  providerId;
  dataSource: any;
  locationArray = [];
  displayedColumns: string[] = ['code', 'name', 'actions'];
  orderPipe: OrderByPipe = new OrderByPipe();
  providerLocationID;
  mailingAddressUtility = new MailingAddressUtility();
  providerLocationSaved: boolean = false;
  existingProvider: boolean;
  finalChanges: boolean = false;
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Provider Number',
            name: 'providerNumber',
            isReadOnly: isEdit,
            type: 'number',
            class: 'form-span-1',
            validators: [Validators.maxLength(9), // length currently limited by INT datatype
                         Validators.required,
                         CustomValidators.valueInUseValidator(this.data?.activeProviderNumbers,'Provider number in use')]
          },
          {
            label: 'First Name',
            name: 'firstName',
            type: 'text',
            class: 'form-span-4',
            validators: Validators.required
          },
          {
            label: 'Middle Name',
            name: 'middleName',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'Last Name',
            name: 'lastName',
            type: 'text',
            class: 'form-span-4',
            validators: Validators.required
          },
          {
            label: 'Title',
            name: 'title',
            type: 'text',
            class: 'form-span-1',
            validators: Validators.required
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            class: 'form-span-1',
            initial: true,
            validators: Validators.required
          },
          {
            label: 'Hold',
            name: 'holdBilling',
            type: 'checkbox',
            class: 'form-span-1',
            initial: false,
            validators: Validators.required
          },
          {
            label: 'NPI',
            name: 'npi',
            type: 'text',
            class: 'form-span-3',
            validators: Validators.maxLength(10) && Validators.minLength(10)
          },
          {
            label: 'Tax ID',
            name: 'taxId',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'SSN',
            name: 'ssn',
            type: 'text',
            class: 'form-span-3', 
            validators: [Validators.maxLength(11),  CustomValidators.ssnValidator]
          },
          {
            label: 'Taxonomy',
            name: 'taxonomy',
            type: 'text',
            class: 'form-span-3',
            validators: [
              Validators.required,
              CustomValidators.taxonomyPatternValidator,
              CustomValidators.taxonomyLengthValidator
            ]
          },
          {
            label: 'Additional ID',
            name: 'additionalId',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'Phone Number',
            name: 'phone',
            type: 'phone',
            class: 'form-span-3'
          },
          {
            label: 'Fax Number',
            name: 'fax',
            type: 'phone',
            class: 'form-span-3'
          },
          {
            label: 'Email',
            name: 'email',
            type: 'email',
            class: 'form-span-6'
          },
          {
            label: 'Bill As',
            name: 'billAs',
            type: 'checkbox',
            class: 'form-span-2',
            initial: false
          },
          {
            label: 'Active Providers',
            name: 'billAsProviderId',
            type: 'select',
            apiService: this.billAsProviderLookup,
            class: 'form-span-4'
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Address',
            name: 'address',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'address1',
            type: 'text',
            class: 'form-span-6',
            validators: [Validators.required, CustomValidators.providerAddressValidator]
          },
          {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'State',
            name: 'stateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Zip',
            name: 'zip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: [Validators.required, CustomValidators.zipCodePatternValidator]
          },
          {
            label: 'Country',
            name: 'countryCode',
            type: 'select',
            apiService: this.countryService,
            class: 'form-span-3',
            initial: 'US'
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Mailing Address',
            name: 'mailingAddress',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Same as Provider Address',
            name: 'sameMailing',
            type: 'checkbox',
            initial: isEdit,
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'mailingAddress1',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'Address Line 2',
            name: 'mailingAddress2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'mailingCity',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'State',
            name: 'mailingStateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3'
          },
          {
            label: 'Zip',
            name: 'mailingZip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: CustomValidators.zipCodePatternValidator
          },
          {
            label: 'Country',
            name: 'mailingCountryCode',
            type: 'select',
            apiService: this.countryService,
            class: 'form-span-3',
          }
        ]
      }
    ];
  }

  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'action',
      displayKey: 'action',
      headerText: '',
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.tiny,
      displayOrder: 0,
      overflowMenuOptions: [
        {
          id: 'delete',
          label: 'Delete'
        }
      ],
      overflowMenuClicked: (value, data) => {
        if (value === 'delete') {
          this.deleteEntry(data);
        }
      }
    },
    {
      id: 'code',
      displayKey: 'code',
      headerText: 'Code',
      displayOrder: 1,
      columnSize: ColumnSizes.narrow,
      filterable: true,
      sortable: true,
      sticky: true
    },
    {
      id: 'name',
      displayKey: 'name',
      headerText: 'Name',
      displayOrder: 2,
      columnSize: ColumnSizes.extraWide,
      sortable: true,
      filterable: true
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ProviderLocationsEntryDialogComponent>,
    private service: ProvidersWrapperService,
    private stateCodesLookupService: StateCodesLookupService,
    private dialogService: DialogService,
    private providerLocationService: ProviderLocationsWrapperService,
    private billAsProviderLookup: ProvidersLookupService,
    private countryService: CountryCodesLookupService,
    public dialog: MatDialog
  ) {
    this.providerId = data?.providerId;
    const isEdit = this.providerId && this.providerId !== '';
    this.formDefinitions = this.getFormDefinitions(isEdit);
    this.viewOnly = data?.viewOnly;
  }

  ngOnInit(): void {
    this.setupForm();
    this.formInitialized = true;
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });

    if (this.providerId && this.providerId !== '') {
      this.existingProvider = true;
      this.service
        .apiV1ProviderDetailsIdGet(this.providerId)
        .pipe(first())
        .subscribe((result) => {
          this.formGroup.patchValue(result);
          this.formGroup.get('active').patchValue(!result.inactive);
          this.formGroup.get('holdBilling').patchValue(result.holdBilling);
          this.formGroup.get('billAs').patchValue(result.billAsProviderId !== null);

          result.providerLocations?.forEach((location) => {
            this.locationArray.push(location);

            this.dataSource = new MatTableDataSource(this.locationArray);
          });
        });
    }

    this.setFormSubscriptions();
  }

  setFormSubscriptions() {
    this.formGroup.get('sameMailing').valueChanges.subscribe((sameAs) => {
      this.mailingAddressUtility.subSameAsFunctionalityToSecondAddress(this.formGroup, sameAs);
    });
  }

  setBillAsEnabled(enabled: boolean) {
    if (enabled) {
      this.formGroup.get('billAsProviderId').enable();
    } else {
      this.formGroup.get('billAsProviderId').disable();
    }
  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;

      const formData = {
        inactive: this.formGroup.get('active').value === true ? false : true,
        holdBilling: this.formGroup.get('holdBilling').value === true,
        providerNumber: this.formGroup.get('providerNumber').value,
        address1: this.formGroup.get('address1').value,
        address2: this.formGroup.get('address2').value,
        city: this.formGroup.get('city').value,
        countryCode: this.formGroup.get('countryCode').value,
        firstName: this.formGroup.get('firstName').value,
        middleName: this.formGroup.get('middleName').value,
        lastName: this.formGroup.get('lastName').value,
        stateCode: this.formGroup.get('stateCode').value,
        title: this.formGroup.get('title').value,
        zip: this.formGroup.get('zip').value,
        phone: this.formGroup.get('phone').value,
        fax: this.formGroup.get('fax').value,
        email: this.formGroup.get('email').value,
        isBillable: true,
        //additionalId: this.formGroup.get('additionalId').value, backend property currently broken. need to resolve
        sameMailing: this.formGroup.get('sameMailing').value === true,
        mailingAddress1: this.formGroup.get('mailingAddress1').value,
        mailingAddress2: this.formGroup.get('mailingAddress2').value,
        mailingCity: this.formGroup.get('mailingCity').value,
        mailingStateCode: this.formGroup.get('mailingStateCode').value,
        mailingCountryCode: this.formGroup.get('mailingCountryCode').value,
        mailingZip: this.formGroup.get('mailingZip').value,
        taxId: this.formGroup.get('taxId').value,
        npi: this.formGroup.get('npi').value,
        taxonomy: this.formGroup.get('taxonomy').value,
        ssn: this.formGroup.get('ssn').value,
        billAsProviderId:
          this.formGroup.get('billAs').value === true ? this.formGroup.get('billAsProviderId').value : null
      };

      let saveObservable: Observable<any>;
      let notification;
      if (this.providerId) {
        notification = 'Provider Updated';
        saveObservable = this.service
          .apiV1ProviderPatchIdPatch(this.providerId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'Provider Added';
        saveObservable = this.service.apiV1ProviderAddPost(formData).pipe(
          map((x: any) => {
            this.providerId = x.id;
          })
        );
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.notificationService.success(notification);
            if (this.finalChanges === false) {
              this.addEntry();
            } else {
              this.dialogRef.close(true);
            }
          },
          (err) => this.notificationService.error('Saving Provider')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }

  reloadLocationsGrid() {
    var newLocArray = [];

    if (this.providerId && this.providerId !== '') {
      this.service
        .apiV1ProviderDetailsIdGet(this.providerId)
        .pipe(first())
        .subscribe((result) => {
          result.providerLocations?.forEach((location) => {
            newLocArray.push(location);
          });

          this.dataSource = new MatTableDataSource(newLocArray);
          this.locationArray = newLocArray;
        });
    }
  }

  addEntry() {
    const dialog = this.dialog.open(ProviderLocationsEntryDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        providerId: this.providerId,
        activeLocationArray: this.locationArray
      },
      height: '250px',
      width: '700px'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.providerLocationSaved = true;
        this.reloadLocationsGrid();
      }
    });
  }

  deleteEntry(data) {
    const modalContent: DialogContent = {
      header: 'Delete Provider Location',
      body: `Are you sure you want to delete "${data.lastName}, ${data.firstName}"? This cannot be undone.`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Delete'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.providerLocationService
          .apiV1ProviderLocationIdDelete(data.id)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.setupForm();
            this.notificationService.notifyNow('Provider Deleted');
          });
      }
    });
  }
  delete(row) {
    const modalContent: DialogContent = {
      header: 'Delete Provider Location',
      body: `Are you sure you want to disassociate this provider from this location?`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Delete'
    };

    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.providerLocationService
          .apiV1ProviderLocationIdDelete(row.locationProviders[0].id)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.reloadLocationsGrid();
            this.notificationService.notifyNow('Provider Location Deleted');
          });
      }
    });
  }
  close() {
    this.finalChanges = true;
    this.save();
  }
}
